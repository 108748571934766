import React from 'react';
import TextMessagingCommon from '../../common/textMessaging/TextMessaging';

const TextMessaging = (props) => {
  return (
    <TextMessagingCommon
      companyId={props.propertyLease?.companyId || null}
      leaseId={props.propertyLease?.id || null}
      leaseStatus={props.propertyLease?.status || null}
    />
  );
};

export default TextMessaging;
