import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import $ from "jquery";
import { FormattedMessage } from "react-intl";

const MessageBar = ({ sendMessage, addErrorToValidationList, fetchChatMessages, leaseStatus}) => {
  const [messageContent, setMessageContent] = useState('');
  const [isMessageSending, setIsMessageSending] = useState(false);

  /**
   * Handle sending text message to a tenant
   * TODO - Instead of fetching the whole list of messages again we should make an optimistic update
   * @param {*} event
   */
  const handleSendMessage = (event) => {
    event.preventDefault();

    if(leaseStatus === 'TERMINATED' || leaseStatus === 'SUSPENDED'){

      $('#send-text-message-to-terminated-lease').modal('show')

    } else {
      sendMessageContent();
    }
  };

  const sendMessageContent = () => {

    setIsMessageSending(true);
    sendMessage(messageContent)
        .then(() => {
          setIsMessageSending(false);
          setMessageContent('');
          fetchChatMessages(1, 25, false); // ? Make an optimistic update instead?
        })
        .catch((error) => {
          addErrorToValidationList(error);
          setIsMessageSending(false);
          window.scrollTo(0, 0);
        });
  };

  return (
    <form onSubmit={handleSendMessage}>
      <div className='input-group'>
        <textarea
          className='form-control'
          placeholder='Message'
          aria-label='Message'
          aria-describedby='button-addon2'
          rows="2" 
          required
          value={messageContent}
          onChange={(event) => {
            setMessageContent(event.target.value);
          }}
        />
        <div className='input-group-append'>
          <button
            className='btn btn-primary'
            type='submit'
            id='button-addon2'
            disabled={isMessageSending}
          >
            {!isMessageSending ? (
              <FontAwesomeIcon icon={['fa', 'paper-plane']} />
            ) : (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                <span className='sr-only'>Loading...</span>
              </>
            )}
          </button>
        </div>
        <div className="modal fade" id="send-text-message-to-terminated-lease" tabIndex="-1" role="dialog" aria-labelledby="send-text-message-to-terminated-lease-label" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content shadow">


              <div className="modal-header bg-dark text-white">
                <h5 className="modal-title" id="send-text-label">
                  Send Text Message
                </h5>
                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                  <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                </button>
              </div>

              <div className="modal-body bg-secondary">
                <p className="mb-0">
                  This lease is suspended/terminated. Do you you want to proceed?
                </p>
              </div>

              <div className="modal-footer bg-secondary rounded-bottom d-block">
                <div className="row">
                  <div className="col-6 text-left">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#send-text-message-to-terminated-lease').modal('hide')}><FormattedMessage id="button.no" /></button>
                  </div>
                  <div className="col-6 text-right">
                    <button type="submit" className="btn btn-primary btn-lg"  onClick={() => {$('#send-text-message-to-terminated-lease').modal('hide'); sendMessageContent()}}><FormattedMessage id="button.yes" /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

MessageBar.propTypes = {
  sendMessage: PropTypes.func,
  addErrorToValidationList: PropTypes.func,
  fetchChatMessages: PropTypes.func,
  leaseStatus: PropTypes.string,
};

export default MessageBar;
