import React from 'react';
import PropTypes from 'prop-types';
import styles from './MessageBubbleStyles.module.scss';
import { MESSAGE_SENDER, MESSAGE_STATUS } from '../constants/textMessagingConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from '../../../../util/dates';

const MessageBubble = ({
  message,
  messageSender,
  messageSenderType,
  messageTime,
  messageStatus,
}) => {
  // Hardcoded 'txt2pay' in the URL to figure out the correct URL. May need to be update it in the future.
  const PAYMENT_LINK_REG = /(https?:\/\/[a-zA-Z0-9-.]+\/txt2pay\/[a-zA-Z0-9-.+]+)/g;

  let paymentLink = message.match(PAYMENT_LINK_REG)?.[0];
  if(paymentLink && paymentLink.length > 1 && paymentLink.endsWith('.')){

    paymentLink = paymentLink.substring(0, paymentLink.length - 1);

  }

  const PAYMENT_LINK = paymentLink;

  return (
    <>
      <div
        className={`row p-2 ${
          messageSenderType === MESSAGE_SENDER.TYPE_TENANT
            ? 'justify-content-start'
            : 'justify-content-end'
        }`}
      >
        <div
          className={`${styles.messageBubble} ${
            messageSenderType === MESSAGE_SENDER.TYPE_TENANT
              ? styles.receivedMessageBubble
              : styles.sentMessageBubble
          } `}
        >
          <span className='text-muted small'>{messageSender}</span>
          <p className={`${styles.message}`}>{message}</p>
          {PAYMENT_LINK && (
            <span>
              <a
                className='small float-right'
                href={PAYMENT_LINK}
                target='_blank'
                rel='noopener noreferrer'
              >
                Payment Link
              </a>
              <br />
            </span>
          )}
          <span className='text-muted small float-right'>
            {formatDate(messageTime, 'YYYY-MM-DD, h:mm a')}
            {(messageSenderType === MESSAGE_SENDER.TYPE_ADMIN ||
              messageSenderType === MESSAGE_SENDER.TYPE_SYSTEM) && (
              <span className='ml-1' title={messageStatus}>
                {messageStatus === MESSAGE_STATUS.TYPE_SUCCESS ? (
                  <FontAwesomeIcon icon={['far', 'check-double']} />
                ) : (
                  <FontAwesomeIcon icon={['far', 'exclamation']} />
                )}
              </span>
            )}
          </span>
        </div>
        <span className='sr-only'>
          {messageSenderType === MESSAGE_SENDER.TYPE_TENANT ? `Received message` : 'Sent Message'}
        </span>
      </div>
    </>
  );
};

MessageBubble.propTypes = {
  message: PropTypes.string,
  messageSender: PropTypes.string,
  messageSenderType: PropTypes.string,
  messageTime: PropTypes.string,
  messageStatus: PropTypes.string,
};

export default MessageBubble;
